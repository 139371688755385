var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "follow-wrapper bj" },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          { staticClass: "seach order-wrapper" },
          [
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "time-arrow-control": true,
                    type: "datetimerange",
                    format: "yyyy-MM-dd HH:mm",
                    "value-format": "yyyy-MM-dd HH:mm",
                    "range-separator": "—",
                    "start-placeholder": "支付时间起",
                    "end-placeholder": "支付时间止",
                  },
                  on: { change: _vm.payChange },
                  model: {
                    value: _vm.pay_at,
                    callback: function ($$v) {
                      _vm.pay_at = $$v
                    },
                    expression: "pay_at",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入商品名称/货号", clearable: "" },
                  model: {
                    value: _vm.from1.product_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "product_name", $$v)
                    },
                    expression: "from1.product_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入订单号", clearable: "" },
                  model: {
                    value: _vm.from1.order_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "order_no", $$v)
                    },
                    expression: "from1.order_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs multiple-select-long" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "选择城市仓",
                    },
                    on: { change: _vm.cityChange },
                    model: {
                      value: _vm.from1.logistics_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "logistics_id", $$v)
                      },
                      expression: "from1.logistics_id",
                    },
                  },
                  _vm._l(_vm.storelist, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs multiple-select" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "选择自提点" },
                    model: {
                      value: _vm.from1.delivery_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "delivery_id", $$v)
                      },
                      expression: "from1.delivery_id",
                    },
                  },
                  _vm._l(_vm.selflist, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入供货单号", clearable: "" },
                  model: {
                    value: _vm.from1.supplier_order_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "supplier_order_no", $$v)
                    },
                    expression: "from1.supplier_order_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    placeholder: "选择供货日期",
                  },
                  model: {
                    value: _vm.from1.supplier_order_create_time,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "supplier_order_create_time", $$v)
                    },
                    expression: "from1.supplier_order_create_time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入默认供货商", clearable: "" },
                  model: {
                    value: _vm.from1.default_supplier_title,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "default_supplier_title", $$v)
                    },
                    expression: "from1.default_supplier_title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入实际供货商", clearable: "" },
                  model: {
                    value: _vm.from1.real_supplier_title,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "real_supplier_title", $$v)
                    },
                    expression: "from1.real_supplier_title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入客户电话", clearable: "" },
                  model: {
                    value: _vm.from1.member_mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "member_mobile", $$v)
                    },
                    expression: "from1.member_mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      placeholder: "请选择二级分类",
                      filterable: "",
                    },
                    model: {
                      value: _vm.from1.cid,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "cid", $$v)
                      },
                      expression: "from1.cid",
                    },
                  },
                  _vm._l(_vm.subList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择集配中心" },
                    model: {
                      value: _vm.from1.logistic_business_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "logistic_business_id", $$v)
                      },
                      expression: "from1.logistic_business_id",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入采购员", clearable: "" },
                  model: {
                    value: _vm.from1.buyer_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "buyer_name", $$v)
                    },
                    expression: "from1.buyer_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择订单状态" },
                    model: {
                      value: _vm.from1.order_state,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "order_state", $$v)
                      },
                      expression: "from1.order_state",
                    },
                  },
                  _vm._l(_vm.orderTypeList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c("el-button", {
              staticClass: "bjsearch",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.Refresh },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.search },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  disabled: !_vm.tableData.length || _vm.disabledExport,
                  type: "warning",
                  icon: "el-icon-upload2",
                },
                on: { click: _vm.onHandleExport },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
              on: { "row-click": _vm.onRowClick },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "80px",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "as_no", align: "center", label: "主图" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "kv pointer",
                          attrs: {
                            src: scope.row.product.kv,
                            "preview-src-list": [scope.row.product.kv],
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleClick.apply(null, arguments)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  align: "center",
                  width: "150",
                  label: "商品名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.product.title)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  width: "150",
                  align: "center",
                  label: "货号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.product.sku_code)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  width: "150",
                  align: "center",
                  label: "采购员",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.buyer_name))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "订单号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.order.order_no)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "订单支付时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$empty.time(scope.row.pay_at))),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "客户名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.member
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.member.fullname)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_state_txt",
                  width: "150",
                  align: "center",
                  label: "订单状态",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "member.mobile",
                  width: "150",
                  align: "center",
                  label: "客户电话",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "product.cate_name",
                  width: "150",
                  align: "center",
                  label: "二级分类",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "城市仓",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$empty.empty(scope.row.logistics_name))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "自提点",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$empty.empty(scope.row.delivery_name))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "集配中心",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.logistic_business_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.logistic_business_name
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "default_supplier_name",
                  width: "150",
                  align: "center",
                  label: "默认供货商",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.default_supplier_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.default_supplier_name
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "supplier_name",
                  width: "150",
                  align: "center",
                  label: "实际供应商",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.supplier_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.supplier_name)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "origin_number",
                  width: "150",
                  align: "center",
                  label: "订货数量",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.origin_number)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "供货单号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.supplier_order
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.supplier_order.supplier_order_no
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  width: "150",
                  align: "center",
                  label: "供货日期",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$empty.empty(
                                scope.row.supplier_order.create_date
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.from1.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("goodsInformation", { ref: "goodsInfoRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }