<template>
  <div class="follow-wrapper bj">
    <div class="title">
      <div class="seach order-wrapper">
        <div class="inputs">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            range-separator="—"
            start-placeholder="支付时间起"
            end-placeholder="支付时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.product_name"
            placeholder="请输入商品名称/货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.order_no"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </div>
        <div class="inputs multiple-select-long">
          <el-select
            @change="cityChange"
            filterable
            v-model="from1.logistics_id"
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs multiple-select">
          <el-select
            v-model="from1.delivery_id"
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selflist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.supplier_order_no"
            placeholder="请输入供货单号"
            clearable
          ></el-input>
        </div>
        <div style="margin-right: 10px">
          <el-date-picker
            v-model="from1.supplier_order_create_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择供货日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.default_supplier_title"
            placeholder="请输入默认供货商"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.real_supplier_title"
            placeholder="请输入实际供货商"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.member_mobile"
            placeholder="请输入客户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.cid"
            clearable
            placeholder="请选择二级分类"
            filterable
          >
            <el-option
              v-for="item of subList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.buyer_name"
            placeholder="请输入采购员"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.order_state"
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="item of orderTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        @row-click="onRowClick"
      >
        <el-table-column type="index" width="80px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="as_no" align="center" label="主图">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.product.kv"
              :preview-src-list="[scope.row.product.kv]"
              class="kv pointer"
              @click.stop="handleClick"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          align="center"
          width="150"
          label="商品名称"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.product">{{
              $empty.empty(scope.row.product.title)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" width="150" align="center" label="货号">
          <template slot-scope="scope">
            <span v-if="scope.row.product">{{
              $empty.empty(scope.row.product.sku_code)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" width="150" align="center" label="采购员">
          <template slot-scope="scope">
            <span>{{ scope.row.buyer_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="订单号"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.order">{{
              $empty.empty(scope.row.order.order_no)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="订单支付时间"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.order">{{
              $empty.time(scope.row.pay_at)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="客户名称"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.member">{{
              $empty.empty(scope.row.member.fullname)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_state_txt"
          width="150"
          align="center"
          label="订单状态"
        >
        </el-table-column>
        <el-table-column
          prop="member.mobile"
          width="150"
          align="center"
          label="客户电话"
        >
        </el-table-column>
        <el-table-column
          prop="product.cate_name"
          width="150"
          align="center"
          label="二级分类"
        >
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="城市仓"
        >
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row.logistics_name) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="自提点"
        >
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row.delivery_name) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.logistic_business_name">{{
              $empty.empty(scope.row.logistic_business_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="default_supplier_name"
          width="150"
          align="center"
          label="默认供货商"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.default_supplier_name">{{
              $empty.empty(scope.row.default_supplier_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="supplier_name"
          width="150"
          align="center"
          label="实际供应商"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.supplier_name">{{
              $empty.empty(scope.row.supplier_name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="origin_number"
          width="150"
          align="center"
          label="订货数量"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.origin_number) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="供货单号"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.supplier_order">{{
              $empty.empty(scope.row.supplier_order.supplier_order_no)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="150"
          align="center"
          label="供货日期"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.supplier_order.create_date) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 商品跟踪具体信息 start -->
    <goodsInformation ref="goodsInfoRef" />
    <!-- 商品跟踪具体信息 end-->
  </div>
</template>
<script>
import { BASE } from "@/api";
import { postOrderDetailFlowListExport } from "@/api/export/center.js";
import { getSubList } from "@/api/generalControl/index.js";
import { getOffsetDate } from "@/utils/tools/date.js";
import goodsInformation from "./modules/goods-information/index.vue";

export default {
  name: "supplier",
  components: { goodsInformation },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      pay_at: [],
      from: {
        pay_at: {
          start: "",
          end: "",
        },
        product_name: "",
        logistic_business_id: "",
        order_no: "",
        logistics_id: "",
        delivery_id: "",
        supplier_order_no: "",
        supplier_order_create_time: "",
        default_supplier_title: "",
        real_supplier_title: "",
        member_mobile: "",
        cid: "",
        buyer_name: "",
        order_state: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        pay_at: {
          start: "",
          end: "",
        },
        product_name: "",
        logistic_business_id: "",
        order_no: "",
        logistics_id: "",
        delivery_id: "",
        supplier_order_no: "",
        supplier_order_create_time: "",
        default_supplier_title: "",
        real_supplier_title: "",
        member_mobile: "",
        cid: "",
        buyer_name: "",
        order_state: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        itemdata: "",
        name: "",
        username: "",
        password: "",
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        itemdata: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      orderTypeList: [
        { value: "2", label: "已付款" },
        { value: "4", label: "配货中" },
        { value: "5", label: "已完成" },
      ], // 订单状态
      options: [],
      options1: [],
      storelist: [],
      selflist: [],
      list: [],
      list1: [],
      subList: [], //二级分类
      disabledExport: false, // 导出禁用
    };
  },
  computed: {
    /**
     * 获得采购员名称
     */
    getSupplierBuyerName() {
      return (data) => {
        const res = data?.buyer?.fullname;
        return res || "-";
      };
    },
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      let url = this.tool.getUrl(window.location.href);
      this.from = url;
      this.from1 = url;
      this.from.logistic_business_id = this.from.logistic_business_id
        ? Number(this.from.logistic_business_id)
        : "";
      this.from1.cid = this.from1.cid ? Number(this.from1.cid) : "";
      this.from.cid = this.from.cid ? Number(this.from.cid) : "";
      this.from1.logistic_business_id = this.from1.logistic_business_id
        ? Number(this.from1.logistic_business_id)
        : "";
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      this.pay_at[0] = this.from1.pay_at.start ? this.from1.pay_at.start : "";
      this.pay_at[1] = this.from1.pay_at.end ? this.from1.pay_at.end : "";
      console.log(this.from1);
    } else {
      /** 支付时间，默认前天18:00-当天18:00 start */
      const { startTime, endTime } = getOffsetDate(-1);
      const suffix = " 18:00:00";
      this.from.pay_at.start = this.from1.pay_at.start = startTime + suffix;
      this.from.pay_at.end = this.from1.pay_at.end = endTime + suffix;
      this.pay_at = [startTime + suffix, endTime + suffix];
      // console.log("🆒 this.pay_at", this.pay_at);
      /** 支付时间，默认前天18:00-当天18:00 end */
    }
    this.hqlist();
    this.getBusinessList();
    this.citylist();
    this.getAjaxSubList();
    //   this.seletlist();
  },
  methods: {
    // 主图预览 隐藏弹窗
    handleClick() {
      this.$refs.goodsInfoRef.onHandleClose();
    },
    // 查看更多字段 弹窗展示
    onRowClick(row, column) {
      this.$refs.goodsInfoRef.onInitData(row);
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.from,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postOrderDetailFlowListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postOrderDetailFlowListExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    // 二级分类列表
    async getAjaxSubList() {
      try {
        const { data } = await getSubList();
        this.subList = data;
        console.log("ajax getAjaxSubList", data);
      } catch (err) {
        console.log("ajax getAjaxSubList err", err);
      }
    },
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    getListData(val) {
      this.$api.general
        .deliveryList({
          logistics_id: val, //必须填写
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.selflist = res.data.data;
        });
    },
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    onServiceSelectedAll() {
      if (this.serviceSelectedAll) {
        // 取消全选
        this.from1.logistics_id = [];
      } else {
        this.from1.logistics_id = this.storelist.map((item) => item.id);
      }
    },
    cityChange(val) {
      this.getListData(val);
    },
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    Refresh() {
      this.from = {
        pay_at: {
          start: "",
          end: "",
        },
        product_name: "",
        logistic_business_id: "",
        order_no: "",
        logistics_id: "",
        delivery_id: "",
        supplier_order_no: "",
        supplier_order_create_time: "",
        default_supplier_title: "",
        real_supplier_title: "",
        member_mobile: "",
        cid: "",
        buyer_name: "",
        order_state: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        pay_at: {
          start: "",
          end: "",
        },
        product_name: "",
        logistic_business_id: "",
        order_no: "",
        logistics_id: "",
        delivery_id: "",
        supplier_order_no: "",
        supplier_order_create_time: "",
        default_supplier_title: "",
        real_supplier_title: "",
        member_mobile: "",
        cid: "",
        buyer_name: "",
        order_state: "",
        page: 1,
        pageSize: 10,
      };
      // 重置默认时间
      const { startTime, endTime } = getOffsetDate(-1);
      const suffix = " 18:00:00";
      this.from.pay_at.start = this.from1.pay_at.start = startTime + suffix;
      this.from.pay_at.end = this.from1.pay_at.end = endTime + suffix;
      this.pay_at = [startTime + suffix, endTime + suffix];
      console.log(this.from, this.from1, "---");
      this.currentPage = 1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    payChange(val) {
      console.log(val);
      if (val) {
        this.from1.pay_at.start = val[0];
        this.from1.pay_at.end = val[1];
      } else {
        this.from1.pay_at.start = "";
        this.from1.pay_at.end = "";
      }
    },
    exportHandle() {
      window.open(
        BASE.PRO1 +
          `/aftersale/list/export?order_no=` +
          this.from1.order_no +
          "&as_at=" +
          this.from1.as_at +
          "&mobile=" +
          this.from1.mobile +
          "&as_no=" +
          this.from1.as_no +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
      console.log(
        BASE.PRO1 +
          `/aftersale/list/export?order_no=` +
          this.from1.order_no +
          "&as_at=" +
          this.from1.as_at +
          "&mobile=" +
          this.from1.mobile +
          "&as_no=" +
          this.from1.as_no +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleEdit(index, row) {
      this.$confirm(
        row.status === 1
          ? "确定要禁用该中心账号吗？"
          : "确定要启用该中心账户吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.login
            .businessstatus({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.status === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: row.status === 1 ? "取消禁用" : "取消启用",
          });
        });
    },

    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      this.$api.general.flowList(this.from).then((res) => {
        console.log(res, "获取数据1");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.follow-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .kv {
    width: 30px;
    height: 30px;
  }

  .order-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 10px 15px;

    .inputs {
      margin-right: 10px;
      margin-bottom: 5px;
    }

    .multiple-select {
      width: 320px;
    }

    .multiple-select-long {
      width: 340px;
    }
  }

  .title {
    background: #ffffff;
    // padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    // .seach {
    //     flex: 1;
    //     display: flex;
    //     flex-wrap: wrap;
    //     // justify-content: space-between;

    //     .inputs {
    //         width: 207px;
    //         margin-right: 8px;
    //     }
    // }

    .bjsearch {
      background: #333333;
      border-color: #333333;
      margin-right: 10px;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}

// .el-picker-panel {
//     left: 1245px !important;
// }
</style>
